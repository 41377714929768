import { A } from "@solidjs/router";
import { ButtonProps } from "./model";
import { splitProps } from "solid-js";

export function ButtonNoAnchor(props: ButtonProps) {
  const [local, others] = splitProps(props, ["theme", "outlined", "link", "onClick", "text", "cls", "onClick"]);
  const lightTheme = local.theme === "light";

  const handleOnClick = () => {
    local.onClick?.();
  };
  return (
    <div
      // {...others}
      onClick={handleOnClick}
      class={`${lightTheme ? "bg#n-50 text#p" : "bg#p text#n-50 rounded-sm"} ${
        local.outlined && lightTheme && "border-2 border#p border-solid rounded-sm"
      }
      ${local.outlined && !lightTheme && "border-2 border#n-50 border-solid rounded-sm"}
      flex items-center justify-center px-15px h-44px text-16px cursor-pointer no-underline ${local.cls ?? ""}`}
    >
      {local.text}
    </div>
  );
}
